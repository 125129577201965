var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VExpansionPanel',[_c('VExpansionPanelHeader',{staticClass:"title"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"flex-grow-1 pl-4"},[(_vm.commentsInCurrentSectionCount['errors'])?_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VChip',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"error"}},'VChip',attrs,false),on),[_vm._v(_vm._s(_vm.commentsInCurrentSectionCount['errors']))])]}}],null,false,1048897912)},[_c('span',[_vm._v("Замечания")])]):_vm._e(),(_vm.commentsInCurrentSectionCount['warnings'])?_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VChip',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'VChip',attrs,false),on),[_vm._v(_vm._s(_vm.commentsInCurrentSectionCount['warnings']))])]}}],null,false,1149824958)},[_c('span',[_vm._v("Исправления")])]):_vm._e()],1)])]),_c('VExpansionPanelContent',{attrs:{"eager":""}},_vm._l((_vm.orderControl(_vm.questions)),function(question){return _c('div',{key:question.code,class:[_vm.$style.item, _vm.errorControl(_vm.commentControl(question.question), question.changed)]},[_c('VRow',{attrs:{"dense":""}},[_c('VCol',{staticClass:"pr-sm-4",attrs:{"cols":"12","md":"3"}},[_c('div',{class:_vm.$style.title,domProps:{"innerHTML":_vm._s(question.label)}}),(question.hint)?_c('div',{class:_vm.$style.hint,domProps:{"innerHTML":_vm._s(question.hint)}}):_vm._e()]),_c('VCol',{attrs:{"cols":"12","md":"9"}},[_c('VRow',{staticClass:"flex-nowrap",attrs:{"dense":""}},[_c('VCol',{staticClass:"flex-grow-1 flex-shrink-1",attrs:{"cols":"auto"}},[_c('EmployeeDetailField',_vm._b({attrs:{"comments":_vm.comments,"readonly":_vm.readonly}},'EmployeeDetailField',Object.assign({}, question),false))],1),_c('VCol',{attrs:{"cols":"auto"}},[(_vm.isReviewer)?[(_vm.commentControl(question.question).length)?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.toViewed(question.question)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-check')}})],1)]}}],null,true)},[_c('span',[_vm._v("Принять")])]):_vm._e()]:_vm._e(),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"fab":"","small":"","depressed":"","color":_vm.isContragent && _vm.commentControl(question.question).length ? 'primary': 'light'},on:{"click":function($event){return _vm.toComments(question.code)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-comment-multiple-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Замечания")])])],2)],1)],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }