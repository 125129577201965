var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VForm',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_c('VExpansionPanels',{attrs:{"flat":"","multiple":""}},[_vm._l((_vm.orderControl(_vm.fields)),function(section){return _c('EmployeeDetailSection',_vm._b({key:section.code,attrs:{"employee":_vm.id,"comments":_vm.comments,"readonly":_vm.readonly}},'EmployeeDetailSection',Object.assign({}, section),false))}),_c('VExpansionPanel',[_c('VExpansionPanelHeader',{staticClass:"title"},[_vm._v("Договоры в которых задействован сотрудник")]),_c('VExpansionPanelContent',{attrs:{"eager":""}},[_c('ContractList',{attrs:{"items":_vm.contracts.items,"count":_vm.contracts.count,"page":_vm.page,"size":_vm.size,"loading":_vm.loading,"disabled":!_vm.hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp'),"selected":_vm.contractsSelected},on:{"update:selected":function($event){_vm.contractsSelected=$event},"click:row":function (ref) {
	var id = ref.id;

	return _vm.$router.push({ name: 'elkp/EmployeeDetailView', params: { employee: id }});
}}})],1)],1)],2)],1),(!_vm.readonly)?_c('VFooter',{attrs:{"app":""}},[_c('div',{staticClass:"py-3 grow"},[_c('VRow',{attrs:{"dense":""}},[(!_vm.contractsSelected.length)?[_c('VCol',{staticClass:"grow",attrs:{"cols":"auto"}},[_c('VBtn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onAgreement}},[_vm._v("Сохранить")]),(_vm.diff)?_c('VBtn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSave}},[_vm._v("Применить")]):_vm._e(),(_vm.diff)?_c('VBtn',{attrs:{"color":"secondary","depressed":""},on:{"click":_vm.onReset}},[_vm._v("Отмена")]):_vm._e()],1),(_vm.contract)?_c('VCol',{attrs:{"cols":"auto"}},[_c('VTooltip',{attrs:{"left":"","color":"error","disabled":!_vm.diff},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"float-right"},'div',attrs,false),on),[(_vm.hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp'))?_c('VBtn',{staticClass:"mr-3",attrs:{"disabled":_vm.diff,"color":"secondary","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'elkp/ContractLinkView', params: { employee: _vm.id } })}}},[_vm._v(" Привязать к существующим договорам ")]):_vm._e(),_c('VBtn',{attrs:{"disabled":_vm.diff,"color":"primary","depressed":""},on:{"click":_vm.onCreate}},[_vm._v(" Добавить нового ")])],1)]}}],null,false,4211010012)},[_c('span',[_vm._v("Есть не сохраненные изменения в текущей анкете")])])],1):_vm._e()]:[_c('VCol',{staticClass:"grow",attrs:{"cols":"auto"}},[_c('VBtn',{attrs:{"color":"primary","depressed":"","loading":_vm.loading},on:{"click":_vm.onDelete}},[_vm._v(" Исключить из выбранных договоров ")]),_c('VBtn',{staticClass:"ml-4",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.contractsSelected = []}}},[_vm._v(" Отмена ")])],1)]],2)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }